import { useRecoilState } from 'recoil'
import { languageState } from '../atoms/LanguageAtom'

export function useLanguage() {
  const [lang, setLangInternal] = useRecoilState(languageState)

  const setLang = (newLang: 'JA' | 'EN') => {
    setLangInternal(newLang)
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', newLang)
    }
  }

  const switchLang = () => {
    const newLang = lang === 'JA' ? 'EN' : 'JA'
    setLang(newLang)
  }

  const translateText = (key: string) => {
    // if (!TRANSLATIONS[key]) return key
    // if (!TRANSLATIONS[key][lang]) return key
    // return TRANSLATIONS[key][lang]
    return 'NEED_TRANSLATION'
  }

  return {
    lang,
    setLang,
    translateText,
    switchLang,
  }
}