import { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie-consent'

const useCookieConsent = () => {
  const [showConsentBanner, setShowConsentBanner] = useState(true)

  // useEffect(() => {
  //   const consentGiven = Cookies.get('gatsby-gdpr-google-tagmanager')
  //   if (consentGiven === 'true') {
  //     setShowConsentBanner(false)
  //     loadGoogleTagManager()
  //   } else if (consentGiven === 'false') {
  //     setShowConsentBanner(false)
  //   }
  // }, [])

  const handleDecline = () => {
    Cookies.set('gatsby-gdpr-google-tagmanager', 'false', { expires: 365 })
    setShowConsentBanner(false)
  }

  const handleOnceDecline = () => {
    Cookies.remove('gatsby-gdpr-google-tagmanager')
    setShowConsentBanner(false)
  }

  const handleAccept = () => {
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 365 })
    setShowConsentBanner(false)
    // loadGoogleTagManager()
  }

  const loadGoogleTagManager = () => {
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtm.js?id=G-KD7GT5BVCQ'
    script.async = true
    document.body.appendChild(script)

    const script2 = document.createElement('script')
    script2.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','G-KD7GT5BVCQ');
    `
    document.body.appendChild(script2)
  }

  return {
    showConsentBanner,
    handleAccept,
    handleDecline,
    handleOnceDecline,
    loadGoogleTagManager,
  }
}

export default useCookieConsent
