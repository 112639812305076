import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import { Helmet } from 'react-helmet'
import useCookieConsent from '../hooks/useCookieConsent'
import { useLanguage } from '../hooks/useLanguage'

const Layout = ({ children, location }) => {
  const [is404, setIs404] = useState(false)
  const { showConsentBanner, handleAccept } = useCookieConsent()
  const { lang } = useLanguage()

  const isAdPage = location.pathname === '/en/' || location.pathname === '/services/business/clinic/' || location.pathname === '/services/business/accountant/'

  const allSitePage = useStaticQuery(graphql`
    query AllPagesQuery {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  useEffect(() => {
    const allPaths = allSitePage.allSitePage.nodes.map(node => node.path)
    if (allPaths && location.pathname) {
      const is404 = location.pathname === '/404/' || !(allPaths?.find((path) => path === location.pathname))
      setIs404(is404)
    }
  }, [allSitePage, location.pathname])

  const cookieConsentText = {
    JA: '当サイトでは、ユーザー体験の向上を目的として、必要最小限のクッキーを使用しています。これらのクッキーは、個人を特定する情報を含まず、第三者と共有されることはありません。詳細については、',
    EN: 'This site uses minimal cookies to improve user experience. These cookies do not contain personally identifiable information and are not shared with third parties. For more details, please see our ',
  }

  const privacyPolicyText = {
    JA: 'プライバシーポリシー',
    EN: 'Privacy Policy',
  }

  const buttonText = {
    JA: 'OK',
    EN: 'Accept',
  }

  return (
    <>
      <Helmet>
        <title></title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-KD7GT5BVCQ'></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-KD7GT5BVCQ');
          `}
        </script>
        <script async src='https://s.yimg.jp/images/listing/tool/cv/ytag.js'></script>
        <script>
          {`
            window.yjDataLayer = window.yjDataLayer || [];
            function ytag() { yjDataLayer.push(arguments); }
            ytag({"type":"ycl_cookie"});
            `}
        </script>
      </Helmet>
      <div className='overflow-hidden'>
        {children}
      </div>
      {!is404 && !isAdPage && showConsentBanner && (
        <CookieConsent
          location='bottom'
          cookieName='gatsby-gdpr-google-tagmanager'
          style={{ background: 'white', color: 'black', borderTop: '0.5px solid gray', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
          buttonText={buttonText[lang]}
          buttonStyle={{
            color: 'black',
            background: 'white',
            border: '1px solid gray',
            borderRadius: '5px',
            padding: '10px 30px',
            transition: 'background-color 0.3s, color 0.3s',
          }}
          buttonClasses='cookie-consent-button'
          onAccept={handleAccept}
        >
          {cookieConsentText[lang]}
          <a href='/privacy' style={{ color: 'black', textDecoration: 'underline' }}>
            {privacyPolicyText[lang]}
          </a>
          {lang === 'JA' ? 'をご覧ください。' : '.'}
        </CookieConsent>
      )}
    </>
  )
}

export default Layout