/* eslint-disable @typescript-eslint/no-var-requires */
const React = require('react')
const { RecoilRoot } = require('recoil')
const Layout = require('./src/components/Layout').default
require('./src/styles/styles.css')
require('./src/styles/noile.css')
require('./src/styles/bg-luminary.css')

// Gatsby browser is the wrapper for browser rendering
exports.wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot>
      <Layout {...props}>
        {element}
      </Layout>
    </RecoilRoot>
  )
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition,
}) => {
  const { hash } = location
  // I need this since the
  if (hash === '#identifyProductsDescription') {
    document.getElementById('identifyProductsDescription').scrollIntoView(false)
  }
  // if the new route is part of the list above, scroll to top (0, 0)
  return false
}

exports.shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}
