import { atom } from 'recoil'

const getInitialLang = (): 'JA' | 'EN' => {
  if (typeof window !== 'undefined') {
    const savedLang = localStorage.getItem('language')
    return (savedLang === 'EN' || savedLang === 'JA') ? savedLang : 'JA'
  }
  return 'JA'
}

export const languageState = atom({
  key: 'language-state',
  default: getInitialLang(),
})

